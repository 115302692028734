import React, { useEffect, useState } from "react";
import { Button, Container, Form, Table } from "react-bootstrap";
import axios from "../../config/axiosConfig";
import ToastService from "../../utils/ToastService";
import Header from "../common/Navbar";
import PaginationComponent from "../common/PaginationComponent";
import SearchInput from "../common/SearchInput";

function SalesOrder() {
  const [excludedItems, setExcludedItems] = useState([]);
  const [sortOption, setSortOption] = useState("latest");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);

  const fetchSalesOrder = async (
    page = currentPage,
    limit = itemsPerPage,
    search = searchTerm,
    sort = sortOption
  ) => {
    try {
      const response = await axios.get("/get-sales-orders", {
        params: { page, limit, searchTerm: search, sortOption: sort },
      });
      if (response.data.success) {
        setExcludedItems(response.data.salesOrders);
        setTotalItems(response.data.totalItems);
      } else {
        ToastService.error("Failed to fetch sales order items.");
      }
    } catch (err) {
      ToastService.error("Error fetching sales order items.");
    }
  };
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
  };
  useEffect(() => {
    fetchSalesOrder(currentPage, itemsPerPage, searchTerm, sortOption);
  }, [currentPage, itemsPerPage, searchTerm, sortOption]);

  const handleResync = async (salesOrderTxnId) => {
    try {
      const response = await axios.post("/resync", {
        sales_order_txn_id: salesOrderTxnId,
      });
      if (response.data.success) {
        fetchSalesOrder();
        ToastService.success(response.data.message);
      } else {
        ToastService.error("Failed to resync sales order");
      }
    } catch (err) {
      ToastService.error("Error resync sales order");
    }
  };
  return (
    <>
      <Header />
      <Container className="mt-4">
        <div className="container mt-4">
          <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center gap-2 w-100">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <h3 className="mb-2 mb-md-0 text-nowrap">Sales Order</h3>
            </div>
            <div className="d-flex flex-column flex-sm-row col-12 col-md-6 align-items-center gap-2">
              <span className="d-none d-md-block mb-0">Sort By:</span>

              <Form.Control
                as="select"
                value={sortOption}
                onChange={(e) => {
                  setSortOption(e.target.value);
                  fetchSalesOrder(
                    currentPage,
                    itemsPerPage,
                    searchTerm,
                    e.target.value
                  );
                }}
                className="flex-grow-1 w-auto"
                style={{ minWidth: "150px" }}
              >
                <option value="latest">Latest Added</option>
                <option value="alphabetical-asc">Customer Name (A-Z)</option>
                <option value="alphabetical-desc">Customer Name (Z-A)</option>
                <option value="salesorder-asc">Sales Order (A-Z)</option>
                <option value="salesorder-desc">Sales Order (Z-A)</option>
              </Form.Control>

              <SearchInput
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                className="flex-grow-1 w-auto"
                style={{ minWidth: "150px" }}
              />
            </div>
          </div>

          {excludedItems?.length > 0 ? (
            <div className="table-responsive mt-5">
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Sales Order ID</th>
                    <th>Customer Name</th>
                    <th>Transaction Date</th>
                    <th>Last Updated</th>
                    <th>Work Order</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {excludedItems.map((item) => (
                    <tr key={item.sales_order_txn_id}>
                      <td>{item.sales_order_id}</td>
                      <td>{item.customer_name || "N/A"}</td>
                      <td>
                        {new Date(item.txn_date).toLocaleString() || "N/A"}
                      </td>
                      <td>
                        {new Date(item.updated_at).toLocaleString() || "N/A"}
                      </td>
                      <td>
                        <a href={item.pdf_url} target="_blank" rel="noreferrer">
                          Download Work Order
                        </a>
                      </td>
                      <td>
                        <Button
                          variant="success"
                          onClick={() => handleResync(item.sales_order_txn_id)}
                          disabled={item.queue_status !== "complete"}
                        >
                          Resync
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <p>No Sales Order found.</p>
          )}
          {excludedItems && excludedItems.length ? (
            <div className="d-flex flex-wrap justify-content-end mt-3 w-100 gap-2">
              <div className="d-flex flex-wrap align-items-center gap-2">
                <div>Items per page:</div>
                <Form.Group controlId="itemsPerPage">
                  <Form.Control
                    as="select"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                    style={{ width: "auto" }}
                  >
                    <option value={10}>10</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </Form.Control>
                </Form.Group>
              </div>
              <div>
                <PaginationComponent
                  totalItems={totalItems}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  onPageChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                    fetchSalesOrder(pageNumber, itemsPerPage, searchTerm);
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </Container>
    </>
  );
}

export default SalesOrder;
