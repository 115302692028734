import React from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const Header = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const handleLogout = () => {
    logout();
    navigate("/");
  };
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "https://leferforge-s3-qbd.s3.amazonaws.com/leferforge.qwc";
    link.download = "leferforge.qwc";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="py-2">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              as={NavLink}
              to="/image-upload"
              className={({ isActive }) => (isActive ? "active" : "")}
              style={{ fontSize: "0.9rem" }}
            >
              Image Upload Management
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/consolidation"
              className={({ isActive }) => (isActive ? "active" : "")}
              style={{ fontSize: "0.9rem" }}
            >
              Item Consolidation Management
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/sales-order"
              className={({ isActive }) => (isActive ? "active" : "")}
              style={{ fontSize: "0.9rem" }}
            >
              Sales Order
            </Nav.Link>
          </Nav>
          <div className="d-flex align-items-center flex-wrap justify-content-end">
            <span
              className="text-white me-3 mb-2 mb-lg-0"
              style={{ fontSize: "0.9rem" }}
            >
              Welcome, {user?.username}!
            </span>
            <Button
              variant="primary"
              size="sm"
              onClick={handleDownload}
              className="me-2 mb-2 mb-lg-0"
            >
              Download QWC File
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={handleLogout}
              className="mb-2 mb-lg-0"
            >
              Logout
            </Button>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
