import { Form } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import "./SearchInput.css";

const SearchInput = ({ searchTerm, setSearchTerm }) => {
  return (
    <Form.Group controlId="searchImages" className="d-flex flex-grow-1">
      <div className="input-group search-input-group flex-grow-1">
        <Form.Control
          type="text"
          placeholder="Search by name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="rounded-end"
        />
        <div className=" search-icon">
          <FaSearch />
        </div>
      </div>
    </Form.Group>
  );
};

export default SearchInput;
 