import React, { useEffect, useState } from "react";
import { Button, Card, Container, Form, ListGroup } from "react-bootstrap";
import axios from "../../config/axiosConfig";
import ImageDeleteConfirmation from "../common/ImageDeleteConfirmation";
import Header from "../common/Navbar";
import PaginationComponent from "../common/PaginationComponent";
import SearchInput from "../common/SearchInput";
import ToastService from "../../utils/ToastService";

function ItemsManagement() {
  const [itemName, setItemName] = useState("");
  const [file, setFile] = useState(null);
  const [excludedItems, setExcludedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [sortOption, setSortOption] = useState("latest");

  const fetchExcludedItems = async (
    page = currentPage,
    limit = itemsPerPage,
    search = searchTerm,
    sort = sortOption
  ) => {
    try {
      const response = await axios.get("/get-excluded-items", {
        params: { page, limit, searchTerm: search, sortOption: sort }, // Include sortOption her
      });
      if (response.data.success) {
        setExcludedItems(response.data.excludedItems);
        setTotalItems(response.data.totalItems);
      } else {
        ToastService.error("Failed to fetch excluded items.");
      }
    } catch (err) {
      ToastService.error("Error fetching excluded items.");
    }
  };
  useEffect(() => {
    fetchExcludedItems(currentPage, itemsPerPage, searchTerm, sortOption);
  }, [currentPage, itemsPerPage, searchTerm, sortOption]);

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("/upload-items", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.data.success) {
        setFile(null);
        document.getElementById("fileInput").value = "";
        ToastService.success("Successfully Upload");
        setExcludedItems(response.data.excludedItems);
      } else {
        ToastService.error(response.data.message);
      }
    } catch (err) {
      ToastService.error(err.response.data.message || "Error uploading file.");
    }
  };
  const handleAddItems = async (e) => {
    e.preventDefault();

    const itemNamesArray = itemName
      .split(",")
      .map((item) => item.trim())
      .filter((item) => item);

    try {
      const response = await axios.post("/add-item", {
        itemNames: itemNamesArray,
      });

      if (response.data.success) {
        setItemName("");
        fetchExcludedItems(currentPage, itemsPerPage, searchTerm);
        ToastService.success("Items added successfully!");
      } else {
        ToastService.error(
          response.data.messages?.[0] || "Error adding items."
        );
      }
    } catch (error) {
      ToastService.error(
        error.response?.data?.message || error.message || "Error adding items."
      );
    }
  };

  const handleDeleteItem = async (item) => {
    try {
      const response = await axios.delete(`/delete-item/${item.item_name}`);
      if (response.status === 200) {
        fetchExcludedItems(currentPage, itemsPerPage, searchTerm);
        ToastService.success("Item deleted successfully!");
      } else {
        ToastService.error(response.data.message);
      }
    } catch (err) {
      ToastService.error(
        err.response?.data?.messages[0] || "Error deleting item."
      );
    }
  };

  return (
    <>
      <Header />
      <Container className="mt-4">
        <div className="mt-4">
          <Card>
            <Card.Header className="d-flex flex-wrap justify-content-between align-items-center gap-2">
              <h3 className="mb-0">Consolidation Management</h3>
              <a href="/sample.csv" download>
                <Button variant="outline-primary">Download Sample CSV</Button>
              </a>
            </Card.Header>
            <Card.Body>
              <div className="container mt-4">
                <div className="row g-4">
                  <div className="col-12 col-lg-6">
                    <div className="mb-4">
                      <Form onSubmit={handleAddItems}>
                        <Form.Group controlId="formItemName">
                          <Form.Control
                            type="text"
                            placeholder="Enter item names, separated by commas"
                            value={itemName}
                            onChange={(e) => setItemName(e.target.value)}
                            required
                          />
                        </Form.Group>
                        <div className="d-flex justify-content-end mt-3">
                          <Button
                            variant="dark"
                            type="submit"
                            disabled={!itemName}
                          >
                            Add Item
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>

                  <div className="col-12 col-lg-6">
                    <div className="mb-4">
                      <Form onSubmit={handleFileUpload}>
                        <Form.Group controlId="formFileUpload">
                          <Form.Control
                            id="fileInput"
                            type="file"
                            onChange={handleFileChange}
                            accept=".csv"
                            required
                            placeholder="Upload CSV File"
                          />
                        </Form.Group>
                        <div className="d-flex justify-content-end mt-3">
                          <Button variant="dark" type="submit" disabled={!file}>
                            Upload
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>

          <Card className="mt-4">
            <Card.Header className="d-flex flex-column flex-md-row justify-content-between align-items-center">
              <h3 className="mb-2 mb-md-0 w-100 w-md-50">
                Current Items Lists
              </h3>
              <div className="d-flex flex-column flex-md-row align-items-center gap-3 w-100 w-md-50">
                <div className="d-flex flex-column flex-md-row align-items-center gap-2 w-100">
                  <span className="d-none d-md-block mb-0">Sort By:</span>
                  <Form.Control
                    as="select"
                    value={sortOption}
                    onChange={(e) => {
                      setSortOption(e.target.value);
                      fetchExcludedItems(
                        currentPage,
                        itemsPerPage,
                        searchTerm,
                        e.target.value
                      );
                    }}
                    className="w-100 w-md-auto"
                  >
                    <option value="latest">Latest Added</option>
                    <option value="alphabetical-asc">Alphabetical (A-Z)</option>
                    <option value="alphabetical-desc">
                      Alphabetical (Z-A)
                    </option>
                  </Form.Control>
                  <div className="mt-2 mt-md-0 w-100">
                    <SearchInput
                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                    />
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <ListGroup className="mt-3">
                {excludedItems.length > 0 ? (
                  excludedItems.map((item) => (
                    <ListGroup.Item
                      key={item.item_name}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>{item.item_name}</div>
                      <ImageDeleteConfirmation
                        item={item}
                        handleDeleteImage={handleDeleteItem}
                        name={item.item_name}
                      />
                    </ListGroup.Item>
                  ))
                ) : (
                  <ListGroup.Item>No items found.</ListGroup.Item>
                )}
              </ListGroup>
            </Card.Body>
          </Card>

          {excludedItems && excludedItems.length ? (
            <div className="d-flex flex-wrap justify-content-end mt-3 w-100 gap-2">
              <div className="d-flex flex-wrap align-items-center gap-2">
                <div>Items per page:</div>
                <Form.Group controlId="itemsPerPage">
                  <Form.Control
                    as="select"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                    style={{ width: "auto" }}
                  >
                    <option value={10}>10</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </Form.Control>
                </Form.Group>
              </div>
              <div>
                <PaginationComponent
                  totalItems={totalItems}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  onPageChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                    fetchExcludedItems(pageNumber, itemsPerPage, searchTerm);
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </Container>
    </>
  );
}

export default ItemsManagement;
