// src/utils/toast.js
import { toast } from "react-toastify";

const ToastService = {
  success: (message) => {
    toast.success(message, { position: "top-right" });
  },
  error: (message) => {
    toast.error(message, { position: "top-right" });
  },
};

export default ToastService;
