import React from "react";
import { Link } from "react-router-dom";
import "./NotFound.css"; // Import the CSS file for styling

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1>404</h1>
        <h2>Oops! Page Not Found</h2>
        <p>It seems the page you're looking for doesn't exist.</p>
        <Link to="/" className="home-link">
          Go to Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
