import React, { useState } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";

const ImageDeleteConfirmation = ({ item, handleDeleteImage, name }) => {
  const [showPopover, setShowPopover] = useState(false);

  const popover = (
    <Popover id={`popover-${item?.imageUrl}`}>
      <Popover.Body>
        <div>{`Are you sure you want to delete this ${name}?`}</div>
        <div className="d-flex justify-content-end mt-2 gap-2">
          <Button
            variant="secondary"
            size="sm"
            onClick={() => setShowPopover(false)}
          >
            No
          </Button>
          <Button
            variant="danger"
            size="sm"
            onClick={() => {
              handleDeleteImage(item);
              setShowPopover(false);
            }}
          >
            Yes
          </Button>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      overlay={popover}
      show={showPopover}
      onToggle={() => setShowPopover(!showPopover)}
      rootClose
    >
      <Button
        variant="danger"
        size="sm"
        onClick={(e) => {
          e.preventDefault();
          setShowPopover(!showPopover);
        }}
      >
        <FaTrash />
      </Button>
    </OverlayTrigger>
  );
};

export default ImageDeleteConfirmation;
