import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
} from "react-bootstrap";
import axios from "../../config/axiosConfig";
import ImageDeleteConfirmation from "../common/ImageDeleteConfirmation";
import Header from "../common/Navbar";
import PaginationComponent from "../common/PaginationComponent";
import SearchInput from "../common/SearchInput";
import ToastService from "../../utils/ToastService";

const ImageUploadManagement = () => {
  const [imageFiles, setImageFiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [imageList, setImageList] = useState([]);
  const [itemName, setItemName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [imagesPerPage, setImagesPerPage] = useState(10);
  const [csvFile, setCsvFile] = useState(null);
  const [sortOption, setSortOption] = useState("latest");
  const [isCSVLoading, setIsCSVLoading] = useState(false);
  const [isMultipleImageLoading, setIsMultipleImageLoading] = useState(false);
  const [isSingleImage, setIsSingleImage] = useState(false);

  const fetchImages = async () => {
    try {
      const response = await axios.get("/get-all-s3-images");
      if (response.status === 200) {
        setImageList(response.data.images);
      }
    } catch (error) {
      ToastService.error("Failed to fetch images.");
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImageFiles(files);
  };

  const handleCSVChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  const handleImageUpload = async (e) => {
    e.preventDefault();

    if ((csvFile || itemName) && !isSingleImage) {
      setIsCSVLoading(true);
    } else {
      setIsMultipleImageLoading(true);
    }

    const formData = new FormData();
    imageFiles.forEach((file) => {
      formData.append("images", file);
    });

    if (itemName && !isSingleImage) {
      formData.append("itemNames", itemName);
    }

    if (csvFile && !isSingleImage) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const csvData = event.target.result;
        const parsedData = Papa.parse(csvData, { header: false });
        const itemNamesFromCSV = parsedData.data
          .slice(1)
          .map((row) => row[0])
          .join(",");
        formData.append("csvItemNames", itemNamesFromCSV);

        try {
          const response = await axios.post("/upload-image", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });

          if (response?.data?.success) {
            resetForm();
            fetchImages();
            ToastService.success(response.data?.message);
          } else {
            ToastService.error(response.data.message);
          }
        } catch (err) {
          ToastService.error("Error uploading images.");
        } finally {
          setIsCSVLoading(false);
        }
      };
      reader.readAsText(csvFile);
    } else {
      try {
        const response = await axios.post("/upload-image", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response?.data?.success) {
          setIsSingleImage(false);
          resetForm();
          fetchImages();
          ToastService.success(response.data?.message);
        } else {
          ToastService.error(response.data.message);
        }
      } catch (err) {
        ToastService.error("Error uploading images.");
      } finally {
        setIsMultipleImageLoading(false);
      }
    }
  };

  const resetForm = () => {
    setItemName("");
    setImageFiles([]);
    setCsvFile(null);
    document.getElementById("fileCSV").value = "";
    document.getElementById("fileMultiUpload").value = "";
    document.getElementById("fileInput").value = "";
  };

  const validExtensions = ["jpg", "jpeg", "png"];

  const sortImages = (images) => {
    if (sortOption === "latest") {
      return [...images].sort(
        (a, b) => new Date(b.lastModified) - new Date(a.lastModified)
      );
    } else if (sortOption === "alphabetical-asc") {
      return [...images].sort((a, b) =>
        a.imageUrl.split("/").pop().localeCompare(b.imageUrl.split("/").pop())
      );
    } else if (sortOption === "alphabetical-desc") {
      return [...images].sort((a, b) =>
        b.imageUrl.split("/").pop().localeCompare(a.imageUrl.split("/").pop())
      );
    } else {
      return images;
    }
  };

  const filteredImages = sortImages(
    imageList.filter((img) => {
      const imageName = img.imageUrl.split("/").pop();
      return (
        imageName &&
        validExtensions.some((ext) => imageName.toLowerCase().endsWith(ext)) &&
        imageName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
  );

  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = filteredImages.slice(
    indexOfFirstImage,
    indexOfLastImage
  );

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const handleImagesPerPageChange = (e) => {
    setImagesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };
  const handleDeleteImage = async (item) => {
    const imageName = item.imageUrl.split("/").pop();
    try {
      const response = await axios.delete("/delete-s3-image", {
        data: { imageKey: imageName },
      });

      if (response.status === 200) {
        setImageList((prevList) =>
          prevList.filter((x) => x.imageUrl !== item.imageUrl)
        );
        ToastService.success("Image deleted successfully!");
      } else {
        ToastService.error(response.data.message);
      }
    } catch (err) {
      ToastService.error("Error deleting item.");
    }
  };

  return (
    <>
      <Header />
      <Container className="mt-4">
        <Form onSubmit={handleImageUpload}>
          <Card className="mb-4">
            <Card.Header>
              <h3>Upload Single or Multiple Images</h3>
            </Card.Header>
            <Card.Body>
              <Form.Group controlId="formFileUpload">
                <Row className="align-items-center">
                  <Col xs={12} md={9}>
                    <Form.Control
                      type="file"
                      onChange={(e) => {
                        handleImageChange(e);
                        setIsSingleImage(true);
                      }}
                      accept=".jpg,.jpeg,.png,.bmp"
                      required
                      multiple
                      id="fileInput"
                      className="w-100"
                    />
                  </Col>

                  <Col
                    xs={12}
                    md={3}
                    className="mt-3 mt-md-0 d-flex justify-content-md-end"
                  >
                    <Button
                      variant="dark"
                      type="submit"
                      disabled={!isSingleImage || isMultipleImageLoading}
                      className="w-100"
                    >
                      {isMultipleImageLoading ? (
                        <span>Loading...</span>
                      ) : (
                        "Upload Image(s)"
                      )}
                    </Button>
                  </Col>
                </Row>
              </Form.Group>
            </Card.Body>
          </Card>
        </Form>

        <Form onSubmit={handleImageUpload}>
          <Card className="mb-4">
            <Card.Header>
              <h3>Upload Single Image with Multiple Items</h3>
            </Card.Header>
            <Card.Body>
              <Row className="mt-2">
                <Col md={6}>
                  <Form.Group controlId="formItemName">
                    <Form.Label style={{ fontWeight: 500, color: "gray" }}>
                      Item Names (comma-separated)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={itemName}
                      onChange={(e) => setItemName(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group controlId="formCSVFile">
                    <Form.Label style={{ fontWeight: 500, color: "gray" }}>
                      Upload CSV File
                    </Form.Label>
                    <Form.Control
                      type="file"
                      accept=".csv"
                      onChange={handleCSVChange}
                      id="fileCSV"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group controlId="formFileUpload" className="mt-2">
                <Row className="align-items-center">
                  <Col xs={12} md={8}>
                    <Form.Label style={{ fontWeight: 500, color: "gray" }}>
                      Select Single Image
                    </Form.Label>
                    <Form.Control
                      type="file"
                      onChange={handleImageChange}
                      accept=".jpg,.jpeg,.png"
                      required
                      id="fileMultiUpload"
                    />
                  </Col>
                  <Col xs={12} md={4} className="mt-3 mt-md-0 ">
                    <Button
                      variant="dark"
                      type="submit"
                      disabled={
                        (!csvFile && !itemName?.length) ||
                        isSingleImage ||
                        !imageFiles?.length ||
                        isCSVLoading
                      }
                      className="w-100 "
                      style={{ marginTop: "27px" }}
                    >
                      {isCSVLoading ? (
                        <span>Loading...</span>
                      ) : (
                        "Upload Image with Multiple Items"
                      )}
                    </Button>
                  </Col>
                </Row>
              </Form.Group>
            </Card.Body>
          </Card>
        </Form>

        <Card className="mb-4">
          <Card.Header className="d-flex flex-column flex-md-row justify-content-between align-items-center">
            <h3 className="mb-2 mb-md-0 w-100 w-md-50">
              Current Uploaded Images
            </h3>
            <div className="d-flex flex-column flex-md-row align-items-center gap-3 w-100 w-md-50">
              <span className="d-block d-md-none mb-0">Sort By:</span>
              <div className="d-flex flex-column flex-md-row align-items-center gap-2 w-100">
                <span className="d-none d-md-block mb-0">Sort By:</span>
                <Form.Control
                  as="select"
                  value={sortOption}
                  onChange={(e) => setSortOption(e.target.value)}
                  className="w-100 w-md-auto"
                >
                  <option value="latest">Latest Added</option>
                  <option value="alphabetical-asc">Alphabetical (A-Z)</option>
                  <option value="alphabetical-desc">Alphabetical (Z-A)</option>
                </Form.Control>
                <div className="mt-2 mt-md-0 w-100">
                  <SearchInput
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                  />
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body
            style={{
              height: "300px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ListGroup
              className="mt-3"
              style={{
                flex: 1,
                overflowY: "auto",
              }}
            >
              {filteredImages.length > 0 ? (
                filteredImages.map((img, index) => {
                  const imageName = img.imageUrl.split("/").pop();
                  return (
                    <ListGroup.Item key={index}>
                      <a
                        href={img.imageUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ color: "blue" }}>{imageName}</span>
                        <ImageDeleteConfirmation
                          item={img}
                          handleDeleteImage={handleDeleteImage}
                          name={imageName}
                        />
                      </a>
                    </ListGroup.Item>
                  );
                })
              ) : (
                <ListGroup.Item>No images found.</ListGroup.Item>
              )}
            </ListGroup>
          </Card.Body>
        </Card>

        {currentImages && currentImages?.length ? (
          <div className="d-flex flex-wrap justify-content-end mt-3 w-100 gap-2">
            <div className="d-flex flex-wrap align-items-center gap-2">
              <div>Items per page:</div>
              <Form.Group controlId="itemsPerPage">
                <Form.Control
                  as="select"
                  value={imagesPerPage}
                  onChange={handleImagesPerPageChange}
                  style={{ width: "auto" }}
                >
                  <option value={10}>10</option>
                  <option value={30}>30</option>
                  <option value={50}>50</option> {/* Fixed the value of 50 */}
                  <option value={100}>100</option>
                </Form.Control>
              </Form.Group>
            </div>
            <PaginationComponent
              totalItems={filteredImages.length}
              itemsPerPage={imagesPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        ) : null}
      </Container>
    </>
  );
};

export default ImageUploadManagement;
