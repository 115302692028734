import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./Login";
import ImageUploadManagement from "./components/ImageUploadManagement";
import NotFound from "./components/NotFound";
import { useAuth } from "./context/AuthContext";
import ItemsManagement from "./components/ItemsManagement";
import SalesOrder from "./components/Salesorder";

const App = () => {
  const { user, loading } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading || loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" variant="primary" />
        <span className="ms-2">Loading...</span>
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={user ? <Navigate to="/image-upload" replace /> : <Login />}
        />
        <Route
          path="/image-upload"
          element={
            user ? <ImageUploadManagement /> : <Navigate to="/" replace />
          }
        />
        <Route
          path="/consolidation"
          element={user ? <ItemsManagement /> : <Navigate to="/" replace />}
        />
        <Route
          path="/sales-order"
          element={user ? <SalesOrder /> : <Navigate to="/" replace />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default App;
